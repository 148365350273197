<template>
  <div id="app">
    <el-container style="height: 920px; border: 1px solid #eee">
      <el-aside v-if="!viewData.isLoginPage" width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu :default-openeds="openeds">
          <el-submenu index="1">
            <template slot="title"><i class="el-icon-s-home"></i>首页</template>
            <el-menu-item-group>
              <el-menu-item index="1-1" @click="push('/')">广告统计</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-2" @click="push('/codeIpStats')">渠道统计</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-date"></i>链接管理</template>
            <el-menu-item-group>
              <el-menu-item index="2-1" @click="push('/link-list/')">链接列表</el-menu-item>
              <el-menu-item index="2-2" @click="push('/add-link/')">添加链接</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title"><i class="el-icon-document"></i>频道管理</template>
            <el-menu-item-group>
              <el-menu-item index="3-1" @click="push('/channel-list/')">频道列表</el-menu-item>
              <el-menu-item index="3-2" @click="push('/add-channel/')">添加频道</el-menu-item>
              <el-menu-item index="3-3" @click="push('/channel-link/')">频道链接</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title"><i class="el-icon-setting"></i>系统设置</template>
            <el-menu-item-group>
              <el-menu-item index="4-1" @click="push('/system-set/')">全局设置</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title"><i class="el-icon-document"></i>渠道管理</template>
            <el-menu-item-group>
              <el-menu-item index="5-1" @click="push('/code-list/')">渠道列表</el-menu-item>
              <el-menu-item index="5-2" @click="push('/add-code/')">添加渠道</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header v-if="!viewData.isLoginPage" style="text-align: right; font-size: 18px">
          <el-row>
            <el-col :span="12"><div class="grid-content" style="text-align: left;">{{viewData.mtitle}}</div></el-col>
            <el-col :span="12">
              <div class="grid-content">
                <span>{{ adminUserName }}</span> | 
                <span class="logout" @click="handleLogout">退出</span>
              </div>
            </el-col>
          </el-row>
        </el-header>
        <router-view @getMessage="showMsg"></router-view>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      openeds: ['1', '2', '3', '4'],
      adminUserName: '',
      viewData: {
        mtitle: '导航管理',
        isLoginPage: false,
      }
    }
  },
  methods: {
    push(url) {
      this.$router.push(url)
    },
    showMsg(val) {
      this.viewData = val
    },
    handleLogout() {
      axios.get('/api/adminLogout.php')
      .then((response)=> {
        if(response.data.code == 0) {
          window.location.href =  '/#/admin-login/'
        }
      })
    }
  },
  mounted () {
    if(!this.viewData.isLoginPage) {
      var _this = this
      axios.post('/api/checkLogin.php', {return_username: 1})
      .then((response)=> {
        if(response.data.code == 0) {
            _this.adminUserName = response.data.data.username
        } else {
            _this.$message({
                type: 'error',
                message: response.data.msg
            })
            window.location.href =  '/#/admin-login/'
        }
      })
    }
  }
}
</script>

<style>
  .el-header {
    background-color: #409eff;
    color: #fff;
    line-height: 60px;
  }
  
  .el-aside {
    color: #fff;
  }

  a {
    text-decoration: none;
  }
  .el-icon-setting {
    color: #fff;
  }
  .logout {
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
  }
</style>

<template>
    <el-container>
      <el-main>
        <el-form :model="form" :rules="rule" ref="addLinkForm" :inline="true">
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="链接" prop="url">
                <el-input v-model="form.url"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="desc_text">
                <el-input v-model="form.desc_text"></el-input>
            </el-form-item>
            <el-form-item label="介绍" prop="total_text">
                <el-input v-model="form.total_text"></el-input>
            </el-form-item>
            <br>
            <el-form-item label="图片1" prop="img1">
                <el-upload
                class="avatar-uploader"
                action="/api/uploadImg.php"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
                :before-upload="beforeAvatarUpload">
                <img v-if="form.img1 != ''" :src="form.img1" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-input v-model="form.img1" placeholder="外部图片请填写图片链接"></el-input>
            </el-form-item>
            <el-form-item label="图片2" prop="img2">
                <el-upload
                class="avatar-uploader"
                action="/api/uploadImg.php"
                :show-file-list="false"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload">
                <img v-if="form.img2 != ''" :src="form.img2" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-input v-model="form.img2" placeholder="外部图片请填写图片链接"></el-input>
            </el-form-item>
            <el-form-item label="图片3" prop="img3">
                <el-upload
                class="avatar-uploader"
                action="/api/uploadImg.php"
                :show-file-list="false"
                :on-success="handleAvatarSuccess3"
                :before-upload="beforeAvatarUpload">
                <img v-if="form.img3 != ''" :src="form.img3" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-input v-model="form.img3" placeholder="外部图片请填写图片链接"></el-input>
            </el-form-item>
            <br>
            <el-form-item>
                <el-button type="primary" @click="handleAdd">添加</el-button>
                <el-button @click="resetForm('addLinkForm')">重置</el-button>
            </el-form-item>
        </el-form>
      </el-main>
    <el-footer>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'

// import axios from 'axios'
export default {
    name: 'AddLink',
    components: {
    },
    data() {
        return {
            form: {
                name: '',
                url: '',
                desc_text: '',
                total_text: '',
                img1: '',
                img2: '',
                img3: '',
            },
            rule: {
                name: [
                    {required: true, message: '请输入链接名称', trigger: 'blur'},
                    {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'},
                ],
                url: [
                    {required: true, message: '请输入链接', trigger: 'blur'},
                    {min: 4, max: 255, message: '长度在 4 到 255 个字符', trigger: 'blur'},
                    {
                        required: true,
                        pattern: /^https?:\/\/.+$/,
                        message: '请输入正确的链接(https://www.baidu.com)',
                        trigger: 'blur'
                    }
                ],
            },
        }
    },
    methods: {
        handleAdd() {
            var _this = this
            this.$refs['addLinkForm'].validate((valid) => {
                if(valid) {
                    // console.log('提交')
                    axios.post('/api/addLink.php', {name: _this.form.name, url: _this.form.url, desc_text: _this.form.desc_text, total_text: _this.form.total_text, img1: _this.form.img1, img2: _this.form.img2, img3: _this.form.img3})
                    .then((response) => {
                        if(response.data.code == 0) {
                            _this.$message({
                                'type': 'success',
                                'message': response.data.msg
                            })
                            _this.resetForm('addLinkForm')
                        } else {
                            _this.$message({
                                'type': 'error',
                                'message': response.data.msg
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleAvatarSuccess1(res) {
            if(res.code == 0) {
                this.form.img1 = res.imageUrl;
            } else {
                this.$message.error('上传失败，上传图片只能是 JPG/PNG 格式!');
            }
        },
        handleAvatarSuccess2(res) {
            if(res.code == 0) {
                this.form.img2 = res.imageUrl;
            } else {
                this.$message.error('上传失败，上传图片只能是 JPG/PNG 格式!');
            }
        },
        handleAvatarSuccess3(res) {
            if(res.code == 0) {
                this.form.img3 = res.imageUrl;
            } else {
                this.$message.error('上传失败，上传图片只能是 JPG/PNG 格式!');
            }
        },
        beforeAvatarUpload(file) {
            let isPic = false;
            if(file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') {
                isPic = true;
            }

            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isPic) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isPic && isLt2M;
        },
    },
    mounted () {
        document.title = '添加链接'
        this.$emit('getMessage', {mtitle: '添加链接', isLoginPage: false});
    }
  }
  </script>
<template>
    <el-container>
      <el-main>
        <div style="margin-bottom: 10px;">
            <el-button type="danger" @click="handleDelSelectTLog">删除选中</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" border stripe height="680" @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column prop="id" label="id">
            </el-table-column>
            <el-table-column prop="name" label="频道名称">
            </el-table-column>
            <el-table-column prop="img_type" label="图片类型">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="200">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑频道</el-button>
                  <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="表单弹窗" :visible.sync="dialogVisible" width="30%">
            <el-form lable-width="80px" :model="form" ref="form">
              <el-form-item label="id">
                  <el-input v-model="form.id" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="频道名称" prop="name">
                  <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="图片类型" prop="img_type">
                    <el-select v-model="form.img_type">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialo-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="handleConfirm" type="primary">确 定</el-button>
            </span>
        </el-dialog>
      </el-main>
    <el-footer>
        <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="formSearch.total">
        </el-pagination>
    </el-footer>
    </el-container>
</template>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
<script>
import axios from 'axios'
export default {
    name: 'ChannelList',
    components: {
    },
    data() {
      return {        
        tableData: [],
        formSearch: {
          pageSize: 10,
          total: 0,
          currentPage: 0,
        },
        form: {
          rowEditing: 0,
          id: 0,
          name: '',
          img_type: 0,
        },
        options: [
            {value: 0, label: 'img1'},
            {value: 1, label: 'img2'},
            {value: 2, label: 'img3'},
        ],
        dialogVisible: false,
        multipleSelection: [],
      }
    },
    methods: {
      handleDelete(index) {
        var _this = this
        this.$confirm('确定删除该条数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('/api/delSelectChannel.php', {ids: index.id+''})
          .then((response) => {
            if(response.data.code == 0) {
              _this.tableData.splice(index.$index, 1)
              _this.$message({
                type: 'success',
                message: response.data.msg
              })
              _this.getDefaultPage(_this.currentPage)
            } else {
              _this.$message({
                type: 'error',
                message: response.data.msg
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleDelSelectTLog() {
        if (this.multipleSelection.length == 0) {
            this.$message({
                type: 'error',
                message: '没有选中任何数据'
            })
            return
        }
        var _this = this
        this.$confirm('确定删除选中数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            var ids = ''
            _this.multipleSelection.forEach(row => {
                ids = ids + row.id + ','
            })
            ids = ids.substring(0, ids.length-1)
             
            axios.post('/api/delSelectChannel.php', {ids: ids})
            .then(response => {
                if(response.data.code == 0) {
                    _this.$message({
                    type: 'success',
                    message: response.data.msg
                    })
                    _this.getDefaultPage()
                } else {
                    _this.$message({
                    type: 'error',
                    message: response.data.msg
                    })
                }
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleCurrentChange(val) { // 分页页数改变
        var valNum = parseInt(val) -1
        this.formSearch.currentPage = valNum
        var _this = this // valNum+'/'+this.pageSize
        axios.post('/api/channelList.php', {page: valNum, offset: this.formSearch.pageSize})
        .then(response => {
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = parseInt(response.data.total)
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
        })
      },
      handleSizeChange(val) { // 每页条数改变
        this.formSearch.pageSize = val
        this.getDefaultPage()
      },
      getDefaultPage(pageN) {
        if (!pageN) {
          pageN = 0
        }
        var _this = this
        axios.post('/api/channelList.php', {page: pageN, offset: this.formSearch.pageSize})
        .then(response => {
          // console.log(response.data)
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = parseInt(response.data.total)
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
          if(response.data.code == 99) {
            window.location.href = '/#/admin-login/'
          }
        })
      },
      handleSearch() {
        this.getDefaultPage()
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleEdit(index) {
        this.dialogVisible = true
        this.form.id = index.id
        this.form.name = index.name
        this.form.img_type = parseInt(index.img_type)
        this.form.rowEditing = index
      },
      // 提交修改 
      handleConfirm() {
          var _this = this
          axios.post('/api/editChannel.php', {id: this.form.id, name: this.form.name, img_type: this.form.img_type})
          .then(response => {
              if(response.data.code == 0) {
                  _this.dialogVisible =false
                  var index = _this.form.rowEditing
                  index.name = _this.form.name
                  index.img_type = _this.form.img_type
                  _this.$message({
                      message: response.data.msg,
                      type: 'success'
                  });
              } else {
                  _this.$message({
                      message: response.data.msg,
                      type: 'error'
                  });
              }
          })
      },
    },
    mounted () {
      document.title = '频道列表'
      this.$emit('getMessage', {mtitle: '频道列表', isLoginPage: false});
      this.getDefaultPage()
    },
    computed: {
      dateRangeStr() {
        if(this.formSearch.dateRange.length == 2) {
          var dateRange = this.formSearch.dateRange
          var dateStr1 = dateRange[0].getFullYear()+'-'+(dateRange[0].getMonth()+1)+'-'+(dateRange[0].getDate())
          var dateStr2 = dateRange[1].getFullYear()+'-'+(dateRange[1].getMonth()+1)+'-'+(dateRange[1].getDate())
          return dateStr1+':'+dateStr2
        } else {
          return ''
        }
      },
    }
  }
  </script>
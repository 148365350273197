import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import VueRouter from "vue-router"
import HelloWorld from '@/components/HelloWorld'
import CodeIpStats from '@/components/codeIpStats'
import LinkList from "@/components/LinkList"
import AddLink from "@/components/AddLink"
import ChannelList from "@/components/ChannelList"
import AddChannel from "@/components/AddChannel"
import ChannelLink from "@/components/ChannelLink"
import SystemSet from '@/components/SystemSet'
import LoginPage from '@/components/LoginPage'
import CodeList from "@/components/CodeList"
import AddCode from "@/components/AddCode"

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: HelloWorld,
    name: "HelloWorld",
  },
  {
    path: "/codeIpStats",
    component: CodeIpStats,
    name: "CodeIpStats",
  },
  {
    path: "/code-list/",
    component: CodeList,
    name: "LinkList",
  },
  {
    path: "/add-code/",
    component: AddCode,
    name: "AddLink",
  },
  {
    path: "/link-list/",
    component: LinkList,
    name: "LinkList",
  },
  {
    path: "/add-link/",
    component: AddLink,
    name: "AddLink",
  },
  {
    path: "/channel-list/",
    component: ChannelList,
    name: "ChannelList",
  },
  {
    path: "/add-channel/",
    component: AddChannel,
    name: "AddChannel",
  },
  {
    path: "/channel-Link/",
    component: ChannelLink,
    name: "ChannelLink",
  },
  {
    path: "/system-set/",
    component: SystemSet,
    name: "SystemSet",
  },
  {
    path: "/admin-login/",
    component: LoginPage,
    name: "LoginPage",
  },
]

const router = new VueRouter({
  routes // （缩写）相当于 routes: routes
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

<template>
    <el-container>
      <el-main>
        <el-form :model="formData" :rules="rule" ref="addChannelForm">
            <el-form-item label="频道名称" prop="name">
                <el-input v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item label="图片类型" prop="img_type">
                <el-select v-model="formData.img_type">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd">添加</el-button>
                <el-button @click="resetForm('addChannelForm')">重置</el-button>
            </el-form-item>
        </el-form>
      </el-main>
    <el-footer>
    </el-footer>
    </el-container>
</template>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
<script>
import axios from 'axios'

// import axios from 'axios'
export default {
    name: 'AddChannel',
    components: {
    },
    data() {
        return {
            formData: {
                name: '',
                img_type: 0,
            },
            options: [
                {value: 0, label: 'img1'},
                {value: 1, label: 'img2'},
                {value: 2, label: 'img3'},
            ],
            options1: [
                {value: 0, label: '未发'},
                {value: 1, label: '已发'},
                {value: 2, label: '禁用'},
            ],
            rule: {
                name: [
                    {required: true, message: '请输入模板名称', trigger: 'blur'},
                ],
                img_type: [
                    {required: true, message: '请选择图片类型', trigger: 'blur'},
                ]
            },
        }
    },
    methods: {
        handleAdd() {
            var _this = this
            this.$refs['addChannelForm'].validate((valid) => {
                if(valid) {
                    axios.post('/api/addChannel.php', {name: _this.formData.name, img_type: _this.formData.img_type})
                    .then((response) => {
                        if(response.data.code == 0) {
                            _this.$message({
                                'type': 'success',
                                'message': response.data.msg
                            })
                            _this.resetForm('addChannelForm')
                        } else {
                            _this.$message({
                                'type': 'error',
                                'message': response.data.msg
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    mounted () {
        document.title = '添加频道'
        this.$emit('getMessage', {mtitle: '添加频道', isLoginPage: false});
    },
  }
  </script>
<template>
  <el-container>
    <el-main>
      <el-form :inline="true" :model="formSearch" ref="formSearch">
          <el-form-item label="日期" prop="dateRange">
            <el-date-picker
              v-model="formSearch.dateRange"
              type="date"
              placeholder="请选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="getDefaultPage()">查询</el-button>
              <el-button @click="resetForm('formSearch')">重置</el-button>
              <el-button type="danger" @click="deleteOldData">删除三天前数据</el-button>
          </el-form-item>
      </el-form>
      <el-table ref="multipleTable" :data="tableData" border stripe height="680">
          <el-table-column prop="id" label="id" width="100">
          </el-table-column>
          <el-table-column prop="name" label="渠道名称">
          </el-table-column>
          <el-table-column prop="code" label="渠道编号">
          </el-table-column>
          <el-table-column prop="visit_ip" label="访问IP">
          </el-table-column>
          <el-table-column prop="click_ip" label="点击Ip">
            <template #default="scope">
              {{ tableData2[scope.row.code] ? tableData2[scope.row.code] : 0  }}
            </template>
          </el-table-column>
          <el-table-column prop="click_number" label="点击次数">
            <template #default="scope">
              {{ tableData3[scope.row.code] ? tableData3[scope.row.code] : 0 }}
            </template>
          </el-table-column>
      </el-table>
    </el-main>
  <el-footer>
      <el-pagination
      background
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-size="formSearch.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="formSearch.total">
      </el-pagination>
  </el-footer>
  </el-container>
</template>
<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  components: {
  },
  data() {
    return {        
      tableData: [],
      tableData2: {},
      tableData3: {},
      formSearch: {
        dateRange: '',
        pageSize: 50,
        total: 0,
        currentPage: 0,
      },
    }
  },
  methods: {
    handleCurrentChange(val) { // 分页页数改变
      this.tableData = []
      this.tableData2 = {}
      this.tableData3 = {}
      var valNum = parseInt(val) -1
      this.formSearch.currentPage = valNum
      var _this = this // valNum+'/'+this.pageSize
      axios.post('/api/codeIPstats_copy.php', {page: valNum, offset: this.formSearch.pageSize, dateRange: this.dateRangeStr})
      .then(response => {
        if(response.data.code == 0) {
          _this.tableData = response.data.data
          let data2 = JSON.parse(JSON.stringify(response.data.data2))
          let data3 = JSON.parse(JSON.stringify(response.data.data3))
          data2.forEach(item => {
            _this.tableData2[item.code] = item.click_ip
          })
          data3.forEach(item => {
            _this.tableData3[item.code] = item.click_number
          })
          _this.formSearch.total = parseInt(response.data.total)
          _this.formSearch.total = parseInt(response.data.total)
        } else {
          _this.$message({
            type: 'error',
            message: response.data.msg
          })
        }
      })
    },
    handleSizeChange(val) { // 每页条数改变
      this.formSearch.pageSize = val
      this.getDefaultPage()
    },
    getDefaultPage(pageN = 0) {
      this.tableData = []
      this.tableData2 = {}
      this.tableData3 = {}
      if (!pageN) {
        pageN = 0
      }
      var _this = this
      axios.post('/api/codeIPstats_copy.php', {page: pageN, offset: this.formSearch.pageSize, dateRange: this.dateRangeStr})
      .then(response => {
        // console.log(response.data)
        if(response.data.code == 0) {
          _this.tableData = response.data.data
          _this.tableData = response.data.data
          let data2 = JSON.parse(JSON.stringify(response.data.data2))
          let data3 = JSON.parse(JSON.stringify(response.data.data3))
          data2.forEach(item => {
            _this.tableData2[item.code] = item.click_ip
          })
          data3.forEach(item => {
            _this.tableData3[item.code] = item.click_number
          })
          _this.formSearch.total = parseInt(response.data.total)
        } else {
          _this.$message({
              type: 'error',
              message: response.data.msg
          })
        }
      })
      .catch(function (error) {
        if(error.response.status == 404 && error.response.data.code == 99) {
          _this.$message({
            type: 'error',
            message: '您还未登录'
          })
          window.location.href = '/#/admin-login/'
        }
      })
    },
    handleSearch() {
      this.getDefaultPage()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    deleteOldData() {
      var _this = this
      this.$confirm('确定清除三天前数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        const today = new Date();
        const previousDay = new Date(today);
        previousDay.setDate(today.getDate() - 2);
        let y = previousDay.getFullYear()
        let m = previousDay.getMonth()+1
        if(m < 10) m = `0${m}` 
        let s = previousDay.getDate()
        if(s < 10) s = `0${s}` 
        let oldDateTime = y+'-'+m+'-'+s + ' 00:00:00'
        axios.post('/api/delSelectVisit.php', {oldDateTime}).then(response => {
          if(response.data.code == 0) {
              _this.$message({
              type: 'success',
              message: response.data.msg
              })
          } else {
              _this.$message({
              type: 'error',
              message: response.data.msg
              })
          }
        })
      })
    }
  },
  mounted () {
    document.title = '渠道统计'
    this.$emit('getMessage', {mtitle: '渠道统计', isLoginPage: false});
    this.formSearch.dateRange = new Date()
    this.getDefaultPage()
  },
  computed: {
    dateRangeStr() {
      if(this.formSearch.dateRange) {
        var dateRange = this.formSearch.dateRange
        let y = dateRange.getFullYear()
        let m = dateRange.getMonth()+1
        if(m < 10) m = `0${m}` 
        let s = dateRange.getDate()
        if(s < 10) s = `0${s}` 
        var dateStr1 = y + '-'+ m + '-' + s
        return dateStr1
      } else {
        let currentDate = new Date();
        let y = currentDate.getFullYear()
        let m = currentDate.getMonth()+1
        if(m < 10) m = `0${m}` 
        let s = currentDate.getDate()
        if(s < 10) s = `0${s}` 
        return y + '-'+ m + '-' + s
      }
    }
  }
}
</script>
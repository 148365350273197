<template>
    <el-container>
      <el-main>
        <el-form :inline="true" :model="formSearch" ref="formSearch">
          <el-form-item label="频道" prop="channel_id">
              <el-select v-model="formSearch.channel_id" placeholder="请选择">
                  <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="resetForm('formSearch')">重置</el-button>
          </el-form-item>
        </el-form>
        <div style="margin-bottom: 10px;">
            <el-button type="primary" @click="handleAddLink">添加链接</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" border stripe height="680">
            <el-table-column prop="cid" label="cid">
            </el-table-column>
            <el-table-column prop="lid" label="lid">
            </el-table-column>
            <el-table-column prop="uuid" label="uuid">
            </el-table-column>
            <el-table-column prop="name" label="链接名称">
            </el-table-column>
            <el-table-column prop="url" label="链接">
            </el-table-column>
            <el-table-column
                label="排序"
                width="200">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.sort" @blur="handleBlur(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column
                label="线路">
                <template slot-scope="scope">
                    <!-- {{scope.row.appKey}} -->
                    <div v-if="scope.row.appKey == 0">全部</div>
                    <div v-else>{{ scope.row.appKey }}</div>

                    <el-button type="text" size="small" @click="editCode(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="200">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="表单弹窗" :visible.sync="dialogVisible" width="30%">
            <el-form lable-width="80px" :model="form" ref="form">
              <el-form-item label="频道">
                  <el-input v-model="formSearch.channel_id" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="链接" prop="channel_id">
              <el-select v-model="form.link_id" placeholder="请选择">
                  <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
                </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialo-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="handleConfirm" type="primary">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="线路弹窗" :visible.sync="codeDialog" width="30%">
            <el-form lable-width="80px" :model="form_code" ref="form_code">
              <el-form-item label="名称">{{ form_code.name }}</el-form-item>
              
              <el-form-item label="线路" prop="channel_id">
              <el-button @click="selectAll">全选</el-button>
              <el-select v-model="form_code.codeNumber" multiple placeholder="请选择线路" style="width: 100%">
                  <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
                </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialo-footer">
            <el-button @click="codeDialog = false">取 消</el-button>
            <el-button @click="handleCode" type="primary">确 定</el-button>
            </span>
        </el-dialog>
      </el-main>
    <el-footer>
        <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="formSearch.total">
        </el-pagination>
    </el-footer>
    </el-container>
</template>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
<script>
import axios from 'axios'
export default {
    name: 'ChannelLink',
    components: {
    },
    data() {
      return {        
        tableData: [],
        formSearch: {
          channel_id: 0,
          pageSize: 10,
          total: 0,
          currentPage: 0,
        },
        form: {
          link_id: 0,
        },
        form_code: {
          name: '',
          codeNumber: ''
        },
        options1: [
            {value: 0, label: '请选择频道'}
        ],
        options2: [
            {value: 0, label: '请选择链接'}
        ],
        options3: [
        ],
        dialogVisible: false,
        codeDialog: false,
        multipleSelection: [],
      }
    },
    methods: {
      editCode(item) {
        this.form_code.name = item.name
        this.form_code.cid = item.cid  //  位置编号
        this.form_code.lid = item.lid // 链接ID
        if(item.appKey == 0) {
          this.form_code.codeNumber = ''
        }else{
          this.form_code.codeNumber = item.appKey.split(',')
        }
        this.codeDialog  = true
      },
      selectAll () {
        this.form_code.codeNumber = this.options3.map(item => item.value);
      },
      handleCode() {
        var _this = this
        let appKey =  ''
        if(this.form_code.codeNumber.length == this.options3.length) {
          appKey = '0'
        }else{
          appKey = this.form_code.codeNumber.join(',')
        }
        axios.post('/api/editClCode.php', {cid: this.form_code.cid, lid: this.form_code.lid, appKey: appKey})
          .then(response => {
              if(response.data.code == 0) {
                _this.$message({
                    message: response.data.msg,
                    type: 'success'
                });
                _this.getDefaultPage(_this.currentPage)
                _this.codeDialog  = false
            } else {
                _this.$message({
                    message: response.data.msg,
                    type: 'error'
                });
            }
        })
      },
      handleCurrentChange(val) { // 分页页数改变
        var valNum = parseInt(val) -1
        this.formSearch.currentPage = valNum
        var _this = this // valNum+'/'+this.pageSize
        axios.post('/api/channelLink.php', {page: valNum, offset: this.formSearch.pageSize, channel_id: this.formSearch.channel_id})
        .then(response => {
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = parseInt(response.data.total)
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
        })
      },
      handleSizeChange(val) { // 每页条数改变
        this.formSearch.pageSize = val
        this.getDefaultPage()
      },
      getDefaultPage(pageN) {
        if (!pageN) {
          pageN = 0
        }
        if(this.formSearch.channel_id == 0) {
            this.$message({
              type: 'error',
              message: '请选择频道'
            })
            return
        }
        var _this = this
        axios.post('/api/channelLink.php', {page: pageN, offset: this.formSearch.pageSize, channel_id: this.formSearch.channel_id})
        .then(response => {
          // console.log(response.data)
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = parseInt(response.data.total)
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
          if(response.data.code == 99) {
            window.location.href = '/#/admin-login/'
          }
        })
      },
      handleSearch() {
        this.getDefaultPage()
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 提交添加渠道-链接绑定 
      handleConfirm() {
        if(this.formSearch.channel_id == 0) {
            this.$message({
              type: 'error',
              message: '请选择频道'
            })
            return
        }
          var _this = this
          axios.post('/api/addChannelLink.php', {cid: this.formSearch.channel_id, lid: this.form.link_id})
          .then(response => {
              if(response.data.code == 0) {
                  _this.dialogVisible =false
                  _this.$message({
                      message: response.data.msg,
                      type: 'success'
                  });
                  _this.getDefaultPage(_this.currentPage)
              } else {
                  _this.$message({
                      message: response.data.msg,
                      type: 'error'
                  });
              }
            })
      },
      handleBlur(index) {
        var _this = this
        axios.post('/api/editClSort.php', {cid: index.cid, lid: index.lid, sort: index.sort})
          .then(response => {
              if(response.data.code == 0) {
                _this.$message({
                    message: response.data.msg,
                    type: 'success'
                });
                _this.getDefaultPage(_this.currentPage)
            } else {
                _this.$message({
                    message: response.data.msg,
                    type: 'error'
                });
            }
        })
      },
      handleDelete(index) {
        var _this = this
        this.$confirm('确定删除该条数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('/api/delCl.php', {cid: index.cid, lid: index.lid})
          .then((response) => {
            if(response.data.code == 0) {
              _this.tableData.splice(index.$index, 1)
              _this.$message({
                type: 'success',
                message: response.data.msg
              })
              _this.getDefaultPage(_this.currentPage)
            } else {
              _this.$message({
                type: 'error',
                message: response.data.msg
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleAddLink() {
        if(this.formSearch.channel_id == 0) {
            this.$message({
              type: 'error',
              message: '请选择频道'
            })
            return
        }
        this.dialogVisible = true
        var _this = this
        // 获取未在当前频道添加的所有有效链接
        axios.post('/api/unbindLink.php', {channel_id: this.formSearch.channel_id})
        .then(response => {
          _this.options2 = [{value: 0, label: '请选择链接'}]
          _this.form.link_id = 0
          if(response.data.code == 0) {
            response.data.data.forEach(link => {
                _this.options2.push({value: link.id, label: link.name})
            });
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
          if(response.data.code == 99) {
            window.location.href = '/#/admin-login/'
          }
        })
    },
    },
    mounted () {
      document.title = '频道链接'
      this.$emit('getMessage', {mtitle: '频道链接', isLoginPage: false});
      var _this = this
      axios.post('/api/channelList.php', {page: 0, offset: 1000})
        .then(response => {
          if(response.data.code == 0) {
            response.data.data.forEach(ch => {
                _this.options1.push({value: ch.id, label: ch.name})
            });
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
          if(response.data.code == 99) {
            window.location.href = '/#/admin-login/'
          }
        })
      axios.post('/api/codeList.php', {page: 0, offset: 1000})
        .then(response => {
          if(response.data.code == 0) {
            response.data.data.forEach(ch => {
                _this.options3.push({value: ch.code, label: ch.name})
            });
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
          if(response.data.code == 99) {
            window.location.href = '/#/admin-login/'
          }
        })
    },
    computed: {
      dateRangeStr() {
        if(this.formSearch.dateRange.length == 2) {
          var dateRange = this.formSearch.dateRange
          var dateStr1 = dateRange[0].getFullYear()+'-'+(dateRange[0].getMonth()+1)+'-'+(dateRange[0].getDate())
          var dateStr2 = dateRange[1].getFullYear()+'-'+(dateRange[1].getMonth()+1)+'-'+(dateRange[1].getDate())
          return dateStr1+':'+dateStr2
        } else {
          return ''
        }
      },
    }
  }
  </script>
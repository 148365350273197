<template>
  <el-container>
    <el-main>
      <el-form :inline="true" :model="formSearch" ref="formSearch">
          <el-form-item label="日期" prop="dateRange">
            <el-date-picker
              v-model="formSearch.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="resetForm('formSearch')">重置</el-button>
              <el-button type="danger" @click="deleteOldData">删除一周前数据</el-button>
          </el-form-item>
      </el-form>
      <el-table ref="multipleTable" :data="tableData" border stripe height="680">
          <el-table-column prop="id" label="id" width="100">
          </el-table-column>
          <el-table-column prop="name" label="名称">
          </el-table-column>
          <el-table-column prop="url" label="链接">
          </el-table-column>
          <el-table-column prop="click_count" label="点击数">
          </el-table-column>
      </el-table>
    </el-main>
  <el-footer>
      <el-pagination
      background
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-size="formSearch.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="formSearch.total">
      </el-pagination>
  </el-footer>
  </el-container>
</template>
<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  components: {
  },
  data() {
    return {        
      tableData: [],
      formSearch: {
        dateRange: [],
        pageSize: 50,
        total: 0,
        currentPage: 0,
      },
    }
  },
  methods: {
    handleCurrentChange(val) { // 分页页数改变
      this.tableData = []
      var valNum = parseInt(val) -1
      this.formSearch.currentPage = valNum
      var _this = this // valNum+'/'+this.pageSize
      axios.post('/api/statistics.php', {page: valNum, offset: this.formSearch.pageSize, dateRange: this.dateRangeStr})
      .then(response => {
        if(response.data.code == 0) {
          _this.tableData = response.data.data
          _this.formSearch.total = parseInt(response.data.total)
        } else {
          _this.$message({
            type: 'error',
            message: response.data.msg
          })
        }
      })
    },
    handleSizeChange(val) { // 每页条数改变
      this.formSearch.pageSize = val
      this.getDefaultPage()
    },
    getDefaultPage(pageN) {
      this.tableData = []
      if (!pageN) {
        pageN = 0
      }
      var _this = this
      axios.post('/api/statistics.php', {page: pageN, offset: this.formSearch.pageSize, dateRange: this.dateRangeStr})
      .then(response => {
        // console.log(response.data)
        if(response.data.code == 0) {
          _this.tableData = response.data.data
          _this.formSearch.total = parseInt(response.data.total)
        } else {
          _this.$message({
              type: 'error',
              message: response.data.msg
          })
        }
      })
      .catch(function (error) {
        if(error.response.status == 404 && error.response.data.code == 99) {
          _this.$message({
            type: 'error',
            message: '您还未登录'
          })
          window.location.href = '/#/admin-login/'
        }
      })
    },
    handleSearch() {
      this.getDefaultPage()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    deleteOldData() {
      var _this = this
      this.$confirm('确定清除一周前数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        const today = new Date();
        const previousDay = new Date(today);
        previousDay.setDate(today.getDate() - 6);
        let y = previousDay.getFullYear()
        let m = previousDay.getMonth()+1
        if(m < 10) m = `0${m}` 
        let s = previousDay.getDate()
        if(s < 10) s = `0${s}` 
        let oldDateTime = y+'-'+m+'-'+s + ' 00:00:00'
        axios.post('/api/delSelectLog.php', {oldDateTime}).then(response => {
          if(response.data.code == 0) {
              _this.$message({
              type: 'success',
              message: response.data.msg
              })
          } else {
              _this.$message({
              type: 'error',
              message: response.data.msg
              })
          }
        })
      })
    }
  },
  mounted () {
    document.title = '广告统计'
    this.$emit('getMessage', {mtitle: '广告统计', isLoginPage: false});
    let currentDate = new Date();
    this.formSearch.dateRange = [
      currentDate,
      currentDate
    ]
    this.getDefaultPage()
  },
  computed: {
    dateRangeStr() {
      if(this.formSearch.dateRange.length == 2) {
        var dateRange = this.formSearch.dateRange
        var dateStr1 = dateRange[0].getFullYear()+'-'+(dateRange[0].getMonth()+1)+'-'+(dateRange[0].getDate())
        var dateStr2 = dateRange[1].getFullYear()+'-'+(dateRange[1].getMonth()+1)+'-'+(dateRange[1].getDate())
        return dateStr1+':'+dateStr2
      } else {
        let currentDate = new Date();
        let year = currentDate.getFullYear()
        let month = currentDate.getMonth() + 1
        let day = currentDate.getDate()
        return year + '-' + month + '-' + day + ':' + year + '-' + month + '-' + day
      }
    }
  }
}
</script>
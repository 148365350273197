<template>
    <el-container>
      <el-main>
        <el-form :inline="true" :model="formSearch" ref="formSearch">
          <el-form-item label="名称" prop="name">
            <el-input v-model="formSearch.name" placeholder="请输入链接名称"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
              <el-select v-model="formSearch.status" placeholder="请选择">
                  <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="resetForm('formSearch')">重置</el-button>
          </el-form-item>
        </el-form>
        <div style="margin-bottom: 10px;">
            <el-button type="danger" @click="handleDelSelectTLog">删除选中</el-button>
            <el-button @click="toggleSelection()">取消选择</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" border stripe height="680" @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="55">
            </el-table-column>
            <el-table-column prop="id" label="id">
            </el-table-column>
            <el-table-column prop="name" label="名称">
            </el-table-column>
            <el-table-column prop="uuid" label="uuid">
            </el-table-column>
            <el-table-column prop="url" label="链接">
            </el-table-column>
            <el-table-column prop="desc_text" label="简介">
            </el-table-column>
            <el-table-column prop="total_text" label="介绍">
            </el-table-column>
            <el-table-column prop="img1" label="图片1">
            </el-table-column>
            <el-table-column prop="img2" label="图片2">
            </el-table-column>
            <el-table-column prop="img3" label="图片3">
            </el-table-column>
            <el-table-column prop="status" label="状态">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="表单弹窗" :visible.sync="dialogVisible" width="30%">
            <el-form lable-width="80px">
            <el-form-item label="id">
                <el-input v-model="form.id" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="链接">
                <el-input v-model="form.url"></el-input>
            </el-form-item>
            <el-form-item label="简介">
                <el-input v-model="form.desc_text"></el-input>
            </el-form-item>
            <el-form-item label="介绍">
                <el-input v-model="form.total_text"></el-input>
            </el-form-item>
            <el-form-item label="图片1" prop="content">
                <el-upload
                class="avatar-uploader"
                action="/api/uploadImg.php"
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
                :before-upload="beforeAvatarUpload">
                <img v-if="form.img1 != ''" :src="form.img1" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-input v-model="form.img1"></el-input>
            </el-form-item>
            <el-form-item label="图片2" prop="content">
                <el-upload
                class="avatar-uploader"
                action="/api/uploadImg.php"
                :show-file-list="false"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload">
                <img v-if="form.img2 != ''" :src="form.img2" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-input v-model="form.img2"></el-input>
            </el-form-item>
            <el-form-item label="图片3" prop="content">
                <el-upload
                class="avatar-uploader"
                action="/api/uploadImg.php"
                :show-file-list="false"
                :on-success="handleAvatarSuccess3"
                :before-upload="beforeAvatarUpload">
                <img v-if="form.img3 != ''" :src="form.img3" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-input v-model="form.img3"></el-input>
            </el-form-item>
            <el-select v-model="form.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
            </el-select>
            </el-form>
            <span slot="footer" class="dialo-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="handleConfirm" type="primary">确 定</el-button>
            </span>
        </el-dialog>
      </el-main>
    <el-footer>
        <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-size="formSearch.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="formSearch.total">
        </el-pagination>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'
export default {
    name: 'LinkList',
    components: {
    },
    data() {
      return {
        tableData: [],
        formSearch: {
          name: '',
          status: 5,
          pageSize: 10,
          total: 0,
          currentPage: 0,
        },
        options: [
          {value: 0, label: '正常'},
          {value: 1, label: '禁用'},
        ],
        options1: [
          {value: 5, label: '全部'},
          {value: 0, label: '正常'},
          {value: 1, label: '禁用'},
        ],
        options2: [
          {value: 0, label: '全部'},
        ],
        multipleSelection: [],
        dialogVisible: false,
        form: {
            rowEditing: 0,
            id: 0,
            name: '',
            url: '',
            desc_text: '',
            total_text: '',
            img1: '',
            img2: '',
            img3: '',
            status: 0,
        },
      }
    },
    methods: {
      handleDelete(index) {
        var _this = this
        this.$confirm('确定删除该条数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('/api/delSelectLink.php', {ids: index.id+''})
          .then((response) => {
            if(response.data.code == 0) {
              _this.tableData.splice(index.$index, 1)
              _this.$message({
                type: 'success',
                message: response.data.msg
              })
              _this.getDefaultPage(_this.formSearch.currentPage)
            } else {
              _this.$message({
                type: 'error',
                message: response.data.msg
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleDelSelectTLog() {
        if (this.multipleSelection.length == 0) {
            this.$message({
                type: 'error',
                message: '没有选中任何数据'
            })
            return
        }
        var _this = this
        this.$confirm('确定删除选中数据吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            var ids = ''
            _this.multipleSelection.forEach(row => {
                ids = ids + row.id + ','
            })
            ids = ids.substring(0, ids.length-1)
             
            axios.post('/api/delSelectLink.php', {ids: ids})
            .then(response => {
                if(response.data.code == 0) {
                    _this.$message({
                    type: 'success',
                    message: response.data.msg
                    })
                    _this.getDefaultPage(_this.formSearch.currentPage)
                } else {
                    _this.$message({
                    type: 'error',
                    message: response.data.msg
                    })
                }
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleCurrentChange(val) { // 分页页数改变
        var valNum = parseInt(val) -1
        this.formSearch.currentPage = valNum

        var _this = this // 
        axios.post('/api/linkList.php', {page: valNum, offset: this.formSearch.pageSize, name: this.formSearch.name, status: this.formSearch.status})
        .then(response => {
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = parseInt(response.data.total)
          } else {
            _this.$message({
              type: 'error',
              message: response.data.msg
            })
          }
        })
      },
      handleSizeChange(val) { // 每页条数改变
        this.formSearch.pageSize = val
        this.getDefaultPage()
      },
      getDefaultPage(pageN) {
        if (!pageN) {
          pageN = 0
        }

        var _this = this
        axios.post('/api/linkList.php', {page: pageN, offset: this.formSearch.pageSize, name: this.formSearch.name, status: this.formSearch.status})
        .then(response => {
          // console.log(response.data)
          if(response.data.code == 0) {
            _this.tableData = response.data.data
            _this.formSearch.total = parseInt(response.data.total)
          } else {
            _this.$message({
                type: 'error',
                message: response.data.msg
            })
          }
        })
        .catch(function (error) {
          if(error.response.status == 404 && error.response.data.code == 99) {
            _this.$message({
              type: 'error',
              message: '您还未登录'
            })
            window.location.href = '/#/admin-login/'
          }
        })
      },
      handleSearch() {
        this.getDefaultPage()
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleEdit(index) {
            this.dialogVisible = true
            this.form.id = index.id
            this.form.name = index.name
            this.form.url = index.url
            this.form.desc_text = index.desc_text
            this.form.total_text = index.total_text
            this.form.img1 = index.img1
            this.form.img2 = index.img2
            this.form.img3 = index.img3
            this.form.status = parseInt(index.status)
            this.form.rowEditing = index
        },
        // 提交修改 
        handleConfirm() {
            var _this = this
            axios.post('/api/editLink.php', {id: this.form.id, name: this.form.name, url: this.form.url, desc_text: this.form.desc_text, total_text: this.form.total_text, img1: this.form.img1, img2: this.form.img2, img3: this.form.img3, status: this.form.status})
            .then(response => {
                if(response.data.code == 0) {
                    _this.dialogVisible =false
                    var index = _this.form.rowEditing
                    index.name = this.form.name
                    index.url = this.form.url
                    index.desc_text = this.form.desc_text
                    index.total_text = this.form.total_text
                    index.img1 = this.form.img1
                    index.img2 = this.form.img2
                    index.img3 = this.form.img3
                    index.status = this.form.status
                    _this.$message({
                        message: response.data.msg,
                        type: 'success'
                    });
                } else {
                    _this.$message({
                        message: response.data.msg,
                        type: 'error'
                    });
                }
            })
        },
        handleAvatarSuccess1(res) {
            if(res.code == 0) {
                this.form.img1 = res.imageUrl;
            } else {
                this.$message.error('上传失败，上传图片只能是 JPG/PNG 格式!');
            }
        },
        handleAvatarSuccess2(res) {
            if(res.code == 0) {
                this.form.img2 = res.imageUrl;
            } else {
                this.$message.error('上传失败，上传图片只能是 JPG/PNG 格式!');
            }
        },
        handleAvatarSuccess3(res) {
            if(res.code == 0) {
                this.form.img3 = res.imageUrl;
            } else {
                this.$message.error('上传失败，上传图片只能是 JPG/PNG 格式!');
            }
        },
        beforeAvatarUpload(file) {
            let isPic = false;
            if(file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') {
                isPic = true;
            }

            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isPic) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isPic && isLt2M;
        },
    },
    mounted () {
      document.title = '链接列表'
      this.$emit('getMessage', {mtitle: '链接列表', isLoginPage: false});
      this.getDefaultPage();
    },
    computed: {
      dateRangeStr() {
        if(this.formSearch.dateRange.length == 2) {
          var dateRange = this.formSearch.dateRange
          var dateStr1 = dateRange[0].getFullYear()+'-'+(dateRange[0].getMonth()+1)+'-'+(dateRange[0].getDate())
          var dateStr2 = dateRange[1].getFullYear()+'-'+(dateRange[1].getMonth()+1)+'-'+(dateRange[1].getDate())
          return dateStr1+':'+dateStr2
        } else {
          return ''
        }
      }
    }
  }
  </script>
<template>
    <el-container>
      <el-main>
        <el-form :model="formData" ref="systemForm" :rules="rule">
            <el-form-item label="图片CDN开关" prop="cdn_status">
                <el-switch v-model="formData.cdn_status"></el-switch>
            </el-form-item>
            <el-form-item label="图片CDN域名" prop="cdn_domain">
                <el-input v-model="formData.cdn_domain" placeholder="https://www.baidu.com:2199"></el-input>
            </el-form-item>
            <el-form-item label="管理员用户名" prop="username">
                <el-input v-model="formData.username"></el-input>
            </el-form-item>
            <el-form-item label="密码(如不修改密码，请留空)" prop="password">
                <el-input v-model="formData.password" placeholder="(如不修改密码，请留空)" show-password autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="重新输入密码(如不修改密码，请留空)" prop="password1">
                <el-input v-model="formData.password1" placeholder="重新输入密码(如不修改密码，请留空)" show-password autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleChange">修改</el-button>
            </el-form-item>
        </el-form>
      </el-main>
    <el-footer>
    </el-footer>
    </el-container>
</template>
<script>
import axios from 'axios'

// import axios from 'axios'
export default {
    name: 'SystemSet',
    components: {
    },
    data() {
        return {
            formData: {
                cdn_status: false,
                cdn_domain: '',
                username: '',
                password: '',
                password1: '',
            },
            rule: {
                username: [
                    {min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur'},
                    {
                        required: false,
                        pattern: /^[a-z]+[a-z0-9]+$/,
                        message: '用户名仅支持数字、小写英文字母，以小写字母开头',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur'},
                    {
                        required: false,
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: '密码仅支持数字、大小写英文字母',
                        trigger: 'blur'
                    }
                ],
                password1: [
                    {min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur'},
                    {
                        required: false,
                        pattern: /^[a-zA-Z0-9]+$/,
                        message: '密码仅支持数字、大小写英文字母',
                        trigger: 'blur'
                    }
                ],
            },
        }
    },
    methods: {
        handleChange() {
            var _this = this
            this.$refs['systemForm'].validate((valid) => {
                if(valid) {
                    if(_this.formData.password != '' && _this.formData.password != _this.formData.password1) {
                        _this.$message({
                            type: 'error',
                            message: '两次输入密码不一致！'
                        })
                        return
                    }
                    _this.formData.cdn_status = _this.formData.cdn_status ? 0 : 1
                    axios.post('/api/systemEdit.php', _this.formData)
                    .then((response) => {
                        if(response.data.code == 0) {
                            _this.loadSetting()
                            _this.$message({
                                'type': 'success',
                                'message': response.data.msg
                            })
                        } else {
                            _this.$message({
                                'type': 'error',
                                'message': response.data.msg
                            })
                        }
                    })
                } else {
                    return false
                }
            })
        },
        loadSetting() {
            var _this = this
            axios.get('/api/systemSet.php')
            .then((response)=> {
                if(response.data.code == 0) {
                    _this.formData = response.data.data
                    _this.formData.cdn_status = _this.formData.cdn_status == "0" ? true : false
                } else {
                _this.$message({
                    type: 'error',
                    message: response.data.msg
                })
                }
                if(response.data.code == 99) {
                    window.location.href = '/#/admin-login/'
                }
            })
        }
    },
    mounted () {
        document.title = '全局设置'
        this.$emit('getMessage', {mtitle: '全局设置', isLoginPage: false});
        this.loadSetting()
    }
  }
  </script>